@value breakpointMdUp, breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, white, blue, midGrey, grey, yellow from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value productCardOverlayTags from "@/modules/styling/zIndex.module.css";
@value productCardSoldText from "@/modules/styling/zIndex.module.css";

@layer component {
  .container {
    margin-bottom: 40px;
  }

  .seeMoreWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: md;
    align-items: center;
  }

  .seeMoreText {
    span {
      color: blue;
      font-weight: normal;
      font-size: 12px;
      font-family: var(--font-GtAmericaExtended);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .modalContainer {
    padding: 0 sm sm sm;

    @media breakpointMdUp {
      padding: 0 xl xl xl;
    }
  }

  .productListModalContainer {
    list-style: none;
    display: grid;
    gap: sm;
    grid-template-columns: repeat(2, 1fr);

    @media breakpointMdUp {
      grid-template-columns: repeat(6, 1fr);
      gap: md;
    }
  }

  .productAttributesModalContainer {
    margin: sm 0 0 xs;
  }

  .boostedTag {
    position: absolute;
    top: sm;
    right: sm;
    z-index: productCardOverlayTags;
    color: black;
    background: white;
    padding: 0 xs 1px;
    border-radius: borderRadiusMedium;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

    @media breakpointMdUp {
      position: relative;
      top: unset;
      right: unset;
      background: none;
      padding: 0;
      border-radius: 0;
      box-shadow: none;

      /* caption1 styles copied from web-ui-kit */
      font-size: 12px;
      line-height: normal;
      color: midGrey;
    }
  }

  .sizeAttributeText {
    color: grey;
  }

  .likeButton {
    bottom: xs;
  }

  .gradientOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(38, 38, 38, 0.3);
    z-index: productCardOverlayTags;
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  .productGrid {
    display: grid;
    gap: sm;
    grid-template-columns: repeat(3, 1fr);

    @media breakpointMdUp {
      grid-template-columns: repeat(6, 1fr);
      gap: md;
    }
  }

  .listItem {
    list-style-type: none;
  }

  .soldText {
    color: yellow;
    text-transform: uppercase;
    z-index: productCardSoldText;
  }
}
