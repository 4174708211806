@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown  from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .activeHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .activeHeadingText {
    display: flex;
    gap: xs;
    margin-bottom: md;
  }

  .actionButton {
    border: 0;
    color: blue;
    padding: 0;
    background: none;

    span {
      font-family: fontFamilyBody;
      font-weight: 400;
      font-size: 0.875rem;
    }

    &:hover {
      background: none;
      text-decoration: underline;
    }

    &:disabled {
      color: blue;
      background: none;
      text-decoration: none;
    }

    @media breakpointSmUp {
      display: flex;
    }

    @media breakpointMdUp {
      span {
        font-size: 0.875rem;
      }
    }
  }

  .rearrangeButtonsContainer {
    display: flex;
    align-items: center;
    height: 24px;

    @media breakpointMdDown {
      display: none;
    }
  }

  .rearrangeModeActiveButton {
    svg {
      /* override default icon size and spacing */
      width: 18px !important;
      height: 18px !important;
      margin: xs !important;
    }
  }

  .rearrangeButtons {
    display: flex;
    align-items: center;
    gap: md;

    @media breakpointSmDown {
      display: none;
    }
  }

  .productListContainer {
    /* override default shop product list styles */
    margin-bottom: lg !important;
  }

  .sellerLikesUsername {
    margin-bottom: md;
  }

  .seeMoreText {
    display: flex;
    justify-content: space-between;
  }
}
