@value md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .radioButtonsWrapper {
    padding: 0 xl;
  }

  .radioButtons {
    /* custom radio button list styles */

    > div {
      padding-bottom: md;
      border-bottom: 1px solid lightGrey2;

      span {
        margin-left: 0;
      }

      label::before {
        right: 0;
      }
    }
  }

  .viewItemsWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: md;
    border-top: 1px solid lightGrey2;
  }
}
