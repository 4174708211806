@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2, red, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value popoverLayer from "@/modules/styling/zIndex.module.css";

@layer components {
  .container {
    margin-bottom: md;

    @media breakpointSmUp {
      display: none;
    }
  }

  .mobileControls {
    display: flex;
    align-items: center;
    gap: sm;
  }

  .mobileButton {
    background: none;
    cursor: pointer;
    border: 1px solid lightGrey2;
  }

  .mobileFilterButton {
    composes: mobileButton;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobileFilterButton--active {
    position: relative;

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      background: red;
      top: 1px;
      right: 1px;
      border-radius: 50%;
    }
  }

  .mobileSortButton {
    composes: mobileButton;
    padding: 0 22px;
    border-radius: 24px;
    height: 44px;
  }

  .filtersContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: popoverLayer;
    background: white;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: md;

    @media breakpointLgUp {
      position: relative;
      z-index: unset;
      width: unset;
      height: unset;
      overflow-y: unset;
      flex-flow: row wrap;
    }
  }

  /* This class must be after .filtersContainer to take precedence */
  .hidden {
    display: none;

    @media breakpointLgUp {
      display: inherit;
    }
  }

  .dropdownsContainer {
    margin: 0 lg;

    @media breakpointLgUp {
      display: flex;
      gap: md;
      margin: 0;
    }
  }
}
