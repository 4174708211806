@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .noResultsContainer {
    margin: 64px 0;
  }

  .noResultsFound {
    font-size: 1rem;
    margin-bottom: sm;
  }
}
